<template>
<base-popup
    :popup-model="show"
    :title="formTitle"
    :valid="!valid"
    @onCancelButtonClick="closePopupHandler"
    @onSaveButtonClick="saveItemHandler"
>
  <v-form
      :ref="formRef"
      v-model="valid"
      lazy-validation
      class="mt-3"
      enctype="multipart/form-data"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-select
            v-model="currentItem.mailType"
            prepend-icon="mdi-account"
            label="Client Type"
            :items="clientTypes"
            item-text="text"
            item-value="value"
            dense
        />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="!isEditMode">
      <v-col cols="12">
        <v-checkbox
            v-model="isImportCsv"
            label="Is Import from CSV File?"
        />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="isImportCsv">
      <v-col cols="12">
        <v-file-input
            accept=".csv"
            label="Select a CSV File"
            small-chips
            prepend-icon="mdi-file"
            @change.native="csvHandler"
            @click:clear="onClearCsvHandler"
        />
      </v-col>
    </v-row>
    <v-row no-gutters v-else>
      <v-col cols="12">
        <v-text-field
            v-model="currentItem.name"
            label="Name"
            prepend-icon="mdi-rename-box"
            required
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
            v-model="currentItem.email"
            :rules="requiredEmailRule"
            label="Email"
            prepend-icon="mdi-email"
            required
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-subheader class="switch-btn-title">Status</v-subheader>
      </v-col>
      <v-col sm="8">
        <v-switch
            v-model="currentItem.isActive"
            color="primary"
            dense
            inset
            prefix="Status">
        </v-switch>
      </v-col>
    </v-row>
  </v-form>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
export default {
  name: "MailBoxPopup",
  components: {BasePopup},
  mixins: [PopupMixin],
  computed: {
    formTitle() {
      if(this.isEditMode) {
        this.isImportCsv = false;
        return 'Edit Mail Box'
      }

      return 'Add Mail Box';
    }
  },
  data() {
    return {
      clientTypes: [
        {
          text: 'General Clients',
          value: 1
        },
        {
          text: 'Fixed Client',
          value: 2
        },
        {
          text: 'Special Clients',
          value: 5
        },
        {
          text: 'More Special Clients',
          value: 4
        },
        {
          text: 'Agency Clients',
          value: 3
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>